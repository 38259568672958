<template>
  <div class="home">
    <mast-head></mast-head>
    <b-container>
      <b-row>

        <b-col>
          <div>
            <h2 class="color2 mb-5">Welcome to this online learning experience.</h2>
            <div class="text-left">
              Due to Covid the issue of domestic abuse has unfortunately become more prevalent, and the need to be able
              identify the signs of domestic abuse ever more important.
              <br/>
              <br/>These learning resources aim to address this, enabling more people from a wide range of agencies,
              such as teachers, police, social workers and health professionals, etc. to become more aware of the signs
              and more confident to know how to help and what to do.
              <br/>
              <br/>
              The Domestic Abuse Act signed into law in April 2021 further raises the need for more learning to be
              available on this topic.
            </div>
          </div>
          <div class="notice mx-auto mt-5 font-weight-bold">
            <div>Please note we are in the process of updating these modules.</div>
            <div>
              When these are finalised, you will be required to re-register to access the site and to use the newly
              updated modules. This is scheduled to happen within the next few weeks.
            </div>
          </div>
        </b-col>

      </b-row>
      <b-row class="my-5" v-if="!GET_LOGGED_IN_STATUS">
        <b-col md="1"></b-col>
        <b-col>
          <b-btn md="4" class="background-color7 mb-1 p-3 font-weight-bold" @click="$router.push('login')">Login</b-btn>
        </b-col>
        <b-col>
          <b-btn md="4" class="background-color6 mb-1 p-3 font-weight-bold" @click="$router.push('register')">Register
          </b-btn>
        </b-col>
        <b-col md="1"></b-col>
      </b-row>
      <b-row class="my-5" v-else>

        <b-col>
          <b-btn class="background-color6 p-3 font-weight-bold btn-module" @click="$router.push('modules')">Modules
          </b-btn>
        </b-col>

      </b-row>

    </b-container>

  </div>
</template>

<script>
import Login from "@/views/Login";
import MastHead from "@/components/MastHead";
import {mapState, mapGetters} from "vuex";

export default {
  name: "home",
  components: {
    Login,
    MastHead
  },
  data() {
    return {};
  },
  computed: {
    ...mapState([
      'DEBUG_STATUS',
      'LOGGED_IN_STATUS'
    ]),
    ...mapGetters([
      'GET_LOGGED_IN_STATUS'
    ])
  },
  methods: {}
};
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";
.btn {
  border: none;
  width: 100%;
  min-width: 300px;
  font-size: 1.1em;
}

.btn-module {
  width: 500px;
}


.notice {
  border: 2px solid $color2; // Purple
  background-color: $color3; // Lime
  color: $color0; // Dark Gray for text
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;

  h2 {
    margin-top: 0;
    color: $color2; // Purple
    font-size: 1.5rem;
  }

  p {
    margin: 0.5rem 0;
  }

  a {
    display: inline-block;
    text-decoration: none;
    background-color: $color6; // Crimson
    color: $color1; // White
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin-top: 1rem;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
      background-color: $color7; // Dark Red
    }
  }
}

@media only screen and (max-width: 768px) {
  .btn-module {
    width: 100%;
  }
}
</style>
